import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

const GuestLayout: FC = () => {
  return (
    // <div className="h-screen bg-hazel-gradient bg-cover">
    <div className="h-screen bg-hazel-bone-100">
      <Outlet />
    </div>
  );
};

export default GuestLayout;
