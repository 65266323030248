import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';
import ZoomMediaContext from 'contexts/ZoomMediaContext';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { produce } from 'immer';
import { MediaStream } from '../zoom.config';
import ZoomContext from 'contexts/ZoomContext';
import { Outlet } from 'react-router-dom';
import { IConsultationDetails } from 'utils/models';

interface IZoomVideoOutlet {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;
    useVideoPlayer?: string;
  };
  consultationDetails: IConsultationDetails | undefined;
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

const ZoomVideoOutlet: FC<IZoomVideoOutlet> = ({ meetingArgs, consultationDetails }) => {
  const {
    sdkKey,
    topic,
    signature,
    name,
    password,
    webEndpoint: webEndpointArg,
    enforceGalleryView,
    enforceVB,
    customerJoinId,
    lang,
    useVideoPlayer
  } = meetingArgs;

  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(false);
  const zmClient = useContext(ZoomContext);

  let webEndpoint: any;
  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const galleryViewWithAttach =
    Number(useVideoPlayer) === 1 && (window.crossOriginIsolated || galleryViewWithoutSAB);

  useEffect(() => {
    const init = async () => {
      // await zmClient.init('en-US', `${window.location.origin}/lib`, {
      //   webEndpoint,
      //   enforceMultipleVideos: galleryViewWithoutSAB,
      //   // enforceMultipleVideos: true,
      //   enforceVirtualBackground: vbWithoutSAB,
      //   stayAwake: true,
      //   patchJsMedia: true,
      //   leaveOnPageUnload: false
      // });

      zmClient.init('US-EN', 'Global', {
        webEndpoint,
        enforceMultipleVideos: galleryViewWithoutSAB,
        // enforceMultipleVideos: true,
        enforceVirtualBackground: vbWithoutSAB,
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: false
      });

      try {
        setLoadingText('Joining the session...');
        await zmClient.join(topic, signature, name, password);
        const stream = zmClient.getMediaStream();
        setMediaStream(stream);
        setIsSupportGalleryView(stream.isSupportMultipleVideos());
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        // message.error(e.reason);
        console.log(e.reason);
      }
    };
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [
    sdkKey,
    signature,
    zmClient,
    topic,
    name,
    password,
    webEndpoint,
    galleryViewWithoutSAB,
    customerJoinId,
    lang,
    vbWithoutSAB
  ]);

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (
          reason === ReconnectReason.JoinSubsession ||
          reason === ReconnectReason.MoveToSubsession
        ) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          console.log('Ended by the host');
        }
      }
    },
    [isFailover, zmClient]
  );

  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  const onLeaveOrJoinSession = useCallback(async () => {
    console.log(status);
    if (status === 'closed') {
      setIsLoading(true);
      await zmClient.join(topic, signature, name, password);
      setIsLoading(false);
    } else if (status === 'connected') {
      await zmClient.leave();
      // message.warn('You have left the session.');
      console.log('you have left the session');
    }
  }, [zmClient, status, topic, signature, name, password]);

  /**
   * end session automatically when call is ended
   * @returns
   */
  const handleEndSession = async () => await zmClient.leave();

  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  return (
    <div className="h-screen flex flex-col justify-center bg-hazel-bone-100">
      {loading ? (
        loadingText
      ) : (
        <ZoomMediaContext.Provider value={mediaContext}>
          <Outlet
            context={{
              status,
              onLeaveOrJoinSession,
              handleEndSession,
              isSupportGalleryView,
              galleryViewWithAttach,
              consultationDetails
            }}
          />
        </ZoomMediaContext.Provider>
      )}
    </div>
  );
};

export default ZoomVideoOutlet;
